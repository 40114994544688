

import MasterList from './MasterList';
import HaveList from './HaveList';
import NeedList from './NeedList';


const List = ({ group, mode, setCurrentScreen }) => {

    if (mode === 'have') return (
        <HaveList group={group} setCurrentScreen={setCurrentScreen} />
    )

    if (mode === 'need') return (
        <NeedList group={group} setCurrentScreen={setCurrentScreen} />
    )
 
    return (
       <MasterList group={group} setCurrentScreen={setCurrentScreen} />
    );

}

export default List;