import React, { useState, useEffect } from 'react';

import { doc, getDoc, addDoc, updateDoc, Timestamp, collection } from 'firebase/firestore';
import { auth, db } from '../../config';
import parse from 'html-react-parser';
import Subheading from '../UI/Subheading';

import Modal from "../Modals/Modal";
import Button from '../UI/Button';
import classes from './Modal.module.css';
import Share from '../Share/Share';
import LaunchIcon from '@mui/icons-material/Launch';

const indefinite_article = (phrase) => {

    // Getting the first word 
    var match = /\w+/.exec(phrase);
    if (match)
        var word = match[0];
    else
        return "an";

    var l_word = word.toLowerCase();
    // Specific start of words that should be preceeded by 'an'
    var alt_cases = ["honest", "hour", "hono"];
    for (var i in alt_cases) {
        if (l_word.indexOf(alt_cases[i]) == 0)
            return "an";
    }

    // Single letter word which should be preceeded by 'an'
    if (l_word.length == 1) {
        if ("aedhilmnorsx".indexOf(l_word) >= 0)
            return "an";
        else
            return "a";
    }

    // Capital words which should likely be preceeded by 'an'
    if (word.match(/(?!FJO|[HLMNS]Y.|RY[EO]|SQU|(F[LR]?|[HL]|MN?|N|RH?|S[CHKLMNPTVW]?|X(YL)?)[AEIOU])[FHLMNRSX][A-Z]/)) {
        return "an";
    }

    // Special cases where a word that begins with a vowel should be preceeded by 'a'
    const regexes = [/^e[uw]/, /^onc?e\b/, /^uni([^nmd]|mo)/, /^u[bcfhjkqrst][aeiou]/]
    for (var i in regexes) {
        if (l_word.match(regexes[i]))
            return "a"
    }

    // Special capital words (UK, UN)
    if (word.match(/^U[NK][AIEO]/)) {
        return "a";
    }
    else if (word === word.toUpperCase()) {
        if ("aedhilmnorsx".indexOf(l_word[0]) >= 0)
            return "an";
        else 
            return "a";
    }

    // Basic method of words that begin with a vowel being preceeded by 'an'
    if ("aeiou".indexOf(l_word[0]) >= 0)
        return "an";

    // Instances where y follwed by specific letters is preceeded by 'an'
    if (l_word.match(/^y(b[lor]|cl[ea]|fere|gg|p[ios]|rou|tt)/))
        return "an";

    return "a";
}


const HireBorrow = ( { item, itemInfo, festival, onConfirm, onCancel, group } ) => {

    const aORan = indefinite_article(item.name);
    const hireUrl = festival?.hireurl ? festival?.hireurl : 'https://www.overnightadventures.com/equipment'
    const borrowText = itemInfo.hireOption ? 
        `Or you can borrow it from your mates`:
        `You can borrow ${aORan} ${item.name?.toLowerCase()} from your mates!` ; 

    const [working, setWorking] = useState(false);
    const user = auth.currentUser;

    const recordHireClick = async () => {
        window.open(hireUrl, 'cbHire');
        if (working===true) return;
        setWorking(true);
        //make a copy of the existing group items array
        const updatedItems = [...group?.items];
        //go through all organise properties for this item and update their status - no multiples yet
        updatedItems?.forEach(i => { if (i.id === item.id) { i.organise?.map( org => org.status = 'Hired')}})
        try {
            const groupRef = doc(db, 'groups', group.id);
            await updateDoc(groupRef, {
                items: updatedItems,
                lastUpdate: Timestamp.fromDate(new Date())
            });
        } catch (err) {
            alert("There was a problem updating your group.")
        }
        setWorking(false);

        const newActivity = {
            userid: user.uid,
            activity: 'hire',
            item: item?.name,
            festival: festival?.name,
            createdAt: Timestamp.fromDate(new Date())
        };
        try {
            //add new activity to log collection
            await addDoc(collection(db, "log"), newActivity);
        } catch (err) {
            //console.log(err.message);
        }
        
    }

      if (!item || !festival) return (
        <></>
      )

    
    return (

        <Modal 
            close 
            title={item.name}
            onCancel={()=>onCancel(false)}
            centre={true}
            >
        
          { itemInfo.hireOption && 
                <div style={{marginBottom:20}}>
                    <Subheading> {`You can hire ${aORan} ${item.name?.toLowerCase()} from ${festival?.name}!`} </Subheading>
                    <div className={classes.callToActionContainer}>
                        <Button 
                        className='primary'
                        onClick={recordHireClick}
                        >
                        {`Hire it now`}
                        <LaunchIcon
                        style={{ color: "#000000", fontSize: 20, verticalAlign: 'middle', padding: '0 0 2px 5px'}}
                        />
                        </Button>
                    </div>
                </div>
          }

            <div>
                <Subheading> {borrowText} </Subheading>
                <Share group={group} />
            </div>

        </Modal>


    );

}

export default HireBorrow;