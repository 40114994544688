import React, {useState, useMemo, useEffect } from 'react';

import classes from './ListSection.module.css';

import ListHeader from './ListHeader';
import ListItem from './ListItem';
import ShareChecklistItem from '../Share/ShareChecklistItem';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Modal from '../Modals/Modal';

const ListSection = ( { group, section, filterMembers, readonly, handleDragStart, handleDragOver, handleDragEnd, mode} ) => {

    
    const [isExpanded, setIsExpanded] = useState(false);
    const [items, setItems] = useState();

    //filter items according to which member/s has been seletced in the top filter
    useEffect(() => {
        const items = section.items?.filter((item,index) => {
            return item;
            //apply filterMembers - if filter members includes anyone who has been assigned to this item
            /*let result = false;
            item.organise.forEach(org => {
                if ( filterMembers.includes(org.assigned) ) result = true;
            });
            if (result) return item;*/
        });
        setItems(items);
    }, [section, filterMembers]);

    if (section.title === "Cleaning") return;

    return (

        <div className={classes.checklistSectionContainer}>

            <ListHeader 
                section={section}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                filterMembers={filterMembers}
                group={group}
                readonly={readonly}
                mode={mode}
                />

            {/*CHILD LIST*/}
            { isExpanded && !readonly && 
            <Modal
                align='top'
                width='full'
                back
                title={`${section.title} for ${group.festival.name}`}
                onCancel={() => setIsExpanded(!isExpanded)}
                >
                    <div className={classes.checklistItemsContainer}>
                        <div className={classes.checklistChildTitle}>
                        Do you have everything you need?
                        </div>
                        <div className={classes.checklistItemList}>
                            { items?.map ((item, index) => 
                            <ListItem 
                            group={group}
                            key={index} 
                            item={item} 
                            category={section.title}
                            handleDragStart ={handleDragStart}
                            handleDragOver = {handleDragOver}
                            handleDragEnd = {handleDragEnd}
                            filterMembers={filterMembers}
                            />
                            ) }
                        </div>
                    </div>
                </Modal>
            }

            { isExpanded && readonly && 
                <div className={classes.checklistItemsContainer}>

               { items?.map ((item, index) => 
                    <ShareChecklistItem 
                        group={group}
                        key={index} 
                        item={item} 
                        section={section}
                    />
                ) }

                </div>
            }

        </div>

    );

}

export default ListSection;