
import classes from './ListItemProgress.module.css';

import Button from '../UI/Button';

import { doc, updateDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../config';




const ListItemDone = ({ group, item, filterMembers, methods, itemStatus }) => {

    const saveItemToGroup = async () => {

        //find which item they are editing eg tent
        const itemIndex = group.items.findIndex(i => i.id === item.id);

        //make a copy of the existing group items array
        const updatedItems = [...group.items];

        updatedItems[itemIndex].organise.forEach((org) => {
            //only change the method property of those items that have the relevant method e.g. Bring or Need
            //e.g. I have now sourced all tents that are needed (change Need on these items to Bring)
            if (methods?.includes(org.method)) {
                org.method = "Bring";
            };
        });

        try {
            const groupRef = doc(db, 'groups', group.id);
            await updateDoc(groupRef, {
                items: updatedItems,
                lastUpdate: Timestamp.fromDate(new Date())
            });
        } catch (err) {
            alert("There was a problem updating your item.")
        }

    }

    return (

        <div className={classes.itemStatusContainer} >

            {itemStatus && <div className={classes.itemStatus}> { ` ${itemStatus}` } </div>}
            <Button 
            style={{marginTop:0}}
            onClick={saveItemToGroup}
            >
            {`Got it`}
            </Button>
        </div>

    );

}

export default ListItemDone;