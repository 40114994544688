import React, { useState, useEffect } from 'react';
import classes from './Share.module.css';
import ShareModal from '../Modals/ShareModal';
import Alert from '../Modals/Alert';
import Button from '../UI/Button';
import { auth } from '../../config';
import ReactGA from 'react-ga4';

const Share = ( { group } ) => {

    const [showShare, setShowShare] = useState(false);
    
    //user.isAnonymous

    const [alertMessage, setAlertMessage] = useState();

    useEffect(() => {
      if (!showShare) return;
      if (showShare === true) {
        ReactGA.send({ hitType: "pageview", page: '/sharemates', title: 'Share with mates' });
        //console.log('sending data to GA: share');
      }
  }, [showShare]);


    if (!group) return;

    return (

        <div className={classes.shareContainer}>

          <div className={classes.shareButtonHolder}>
              <Button 
              className='primary'
              onClick={()=>setShowShare(!showShare)}
              >
              {`Ask your mates`}
              </Button>
          </div>

          {showShare && 
            <ShareModal group={group} setShowShare={setShowShare} setAlertMessage={setAlertMessage}/>
            }

          {alertMessage && 
            <Alert 
                title={alertMessage.title}
                message={alertMessage.message}
                onConfirm={() => setAlertMessage()}
                />
            }

      </div>

    );

}

export default Share;