import React, { useState, useEffect } from 'react';

import classes from './ListItem.module.css';

import ListItemProgress from './ListItemProgress';
import ListItemDone from './ListItemDone';
import ListItemShare from './ListItemShare';
import Button from '../UI/Button';

const HaveNeedListItem = ({ group, item, filterMembers, methods, mode, setSelectedItems, selectedItems }) => {

    const [numberItems, setNumberItems] = useState(1);
    const [itemStatus, setItemStatus] = useState();
    const [itemHelper, setItemHelper] = useState();
    
    //if (item?.name === "Tarp") console.log(item);

    useEffect(() => {
        if (!item) return;
        //only count items that have neem assigned the relevant method e.g. bring or need
        const filteredMethodItems = item.organise.filter( subitem => methods?.includes(subitem.method));
        setNumberItems(filteredMethodItems.length);
        //check whether there is a status set in the item organise property - only grab the first property (not doing multiples yet),
        let status = item.organise?.find( org => org.status && org.status!=="Hired"  )?.status
        const helper = (status === 'Offered') ? item.organise?.find( org => org.helper )?.helper : null;
        setItemHelper(helper)
        if (status && helper) status = `${status} by ${helper}`
        setItemStatus(status)

    }, [item, methods]);

    if (!item) return;

    return (

        <div 
            className={classes.checklistItemContainer} 
            key={item.name}>
           
           <div className={ mode === 'have' ? classes.checklistItemNameContainer : classes.checklistItemNameContainerSpread } >
           
                { mode==='have'&& 
                    <ListItemProgress 
                        item={item} 
                        group={group} 
                        filterMembers={filterMembers} 
                        methods={methods} 
                        />
                }

                { item.name }

                { numberItems > 1 && ` x${numberItems}` }

                { mode==='have'&& itemHelper && <div className={classes.itemStatus}>- {itemHelper}'s</div>}

                { mode==='need'&& 
                   <ListItemDone
                        item={item} 
                        group={group} 
                        filterMembers={filterMembers} 
                        methods={methods} 
                        itemStatus={itemStatus}
                        />
                   
                }

                { mode==='share'&& 
                   <ListItemShare
                        item={item} 
                        setSelectedItems={setSelectedItems}
                        selectedItems={selectedItems}
                        />
                   
                }
                
            </div>
        </div>

    );

}

export default HaveNeedListItem;