import React, { useState, useEffect, useContext } from 'react';
import classes from './OnboardingFestival.module.css';

import EditFestival from '../Forms/EditFestival';
import FestivalTile from './FestivalTile';
import Loading from '../UI/Loading';
import Alert from '../Modals/Alert';
import { Checkbox } from 'semantic-ui-react';
import Page from '../Modals/Page';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import TopMenuOnboarding from '../Navigation/TopMenuOnboarding';
import { UserContext } from '../../store/user-context';

import { collection, query, where, onSnapshot, doc, updateDoc, Timestamp, setDoc, addDoc, getDocs } from 'firebase/firestore';
import { signInAnonymously} from 'firebase/auth';
import { auth, db, CATEGORIES } from '../../config';
import Button from '../UI/Button';
import ReactGA from 'react-ga4';

const OnboardingFestival = ({ group, setMode }) => {

  {/*Called from Root. Shows options to select a festival if the user has just signed up or created a group and the group's festival is not specified. */ }

  const [working, setWorking] = useState(false);
  const user = auth.currentUser;
  const [festivals, setFestivals] = useState([]);
  const [festival, setFestival] = useState(group?.festival);
  const [defaultItems, setDefaultItems] = useState([]);
  const [alertMessage, setAlertMessage] = useState();
  const [consent, setConsent] = useState(false);

  const [showResearchModal, setShowResearchModal] = useState(false);

  const selectFestival = (f) => {
    if (consent === false || !consent) {
      setAlertMessage({ title:"Hold on!", message: "Hey you need to check you agree to our terms and conditions first!"});
      return;
      }
    setFestival(f); 
  }

  const addNewUser = async (user, enteredEmail, enteredName) => {
    try {
      //add new user to users collection
      const newUserRef = await setDoc(doc(db, "users", user.uid), {
        id: user.uid,
        email: enteredEmail,
        name: enteredName,
        receiveNotifications: false,
        activeOrganiser: false, //whether they have clicked on any items to organise stuff
        createdAt: Timestamp.fromDate(new Date())
      });
      console.log('Added user to database', user.uid);
      return true;
    } catch (err) {
      setAlertMessage({ title:"Error", message: err.message});
      return false;
    }
  }

  const addNewGroup = async (user, enteredName) => {

    //first pre configure group items. 
    //need to do this after user has been created
    //If it's a custom festival it won't have any items so we have to assign the default items
    const groupDefaultItems = festival.items? [...festival.items] : defaultItems;
    //remove the 'essentials' 'Cleaning' categories for now
    const availableItems = groupDefaultItems.filter(item => (!item.categories.includes('Essentials') && !item.categories.includes('Cleaning')));
    //set up some default stuff for each item
    const preparedItems = availableItems.map((item,index) => (
      {...item, selected: true, organise: [{ assigned: user.uid, checked: false }], order: index}
      ))
    //don't save festival items to DB (we are saving items at the group level)
    const preparedFestival = { ...festival }
    delete preparedFestival.items;

    //add new group to group collection
    try {
      const newGroupRef = await addDoc(collection(db, 'groups'), {
        acknowledgement: true,
        id: '123',
        owner: user.uid,
        memberIds: [user.uid], //member ids only for queries later on
        memberEmails: [user.email], //member emails only for queries later on
        categories: CATEGORIES,
        items: preparedItems,
        festival: preparedFestival,
        members: [
          {
            id: user.uid,
            email:user.email, 
            name:enteredName, 
            avatar: 1,
            editor: true,
            owner: true
          }
        ], //set up membership for group
        createdAt: Timestamp.fromDate(new Date()),
        lastUpdate: Timestamp.fromDate(new Date())
      });
      //add the auto generated id to the created group
      if (newGroupRef && newGroupRef.id) {
        const groupRef = doc(db, 'groups', newGroupRef.id);
        await updateDoc(groupRef, { id: newGroupRef.id});
        //console.log('new group',newGroupRef.id);
      }
      return true;
    } catch (err) {
      setAlertMessage({ title:"Error", message: err.message});
      return false;
    }
}

   {/*On selecting festival or creating custom event - create guest user, then create their first group and save festival to this group */ }
  const launchGuestHandler = () => {
    console.log('launch guest handler');
    setWorking(true);
    signInAnonymously(auth)
    .then(async (userCredential) => {
      //created account, now write to DB
      console.log('Signed in');
      const userAdded = await addNewUser(userCredential.user, null, 'Guest');
      //create group in DB
      const groupAdded = await addNewGroup(userCredential.user, 'Guest');
      setWorking(false);
      
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      const alertText = 'Sorry! Signing in as a guest did not work. '+errorCode+errorMessage;
      setAlertMessage({ title:"Error", message: alertText});
      setWorking(false);
    });
  }

  useEffect(() => {
    if (!festival) return;
    launchGuestHandler();
  }, [festival]);
  
  useEffect(() => {
    if (!ReactGA) return;
    ReactGA.send({ hitType: "pageview", page: '/onboardingFestival', title: 'Onboarding Festival' });
    //console.log('sending to GA: onboardingFestival');
}, []);


 
  useEffect(() => {
    
    //set up listener for festivals
    setFestivals([]);
    const q = query(collection(db, 'festivals'), where("active", "==", true));
    const unsubscribe = onSnapshot(q,
      (querySnapshot) => {
        const festivalsSnapshot = [];
        querySnapshot.forEach((doc) => {
          //this can remove anything from the festivals collection if you don't need it in the app
          const dataObj = { ...doc.data() }
          //delete dataObj.items;
          festivalsSnapshot.push(dataObj);
        });
        setFestivals(festivalsSnapshot);
      },
      (error) => {
        console.log(error.message);
      });

    //they have created a custom event - set up listener for default items / master packing list
    const q2 = query(collection(db, "defaultitems"));
    setDefaultItems([])
    const unsubscribe2 = onSnapshot(q2, (querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => { items.push(doc.data()); });
      setDefaultItems(items);
    });

    return () => {
      unsubscribe();
      unsubscribe2();
      setFestivals();
    };
  }, [user])

  if (working) return <Loading msg='Setting things up' />

  return (
    <div className='container bg-green-gradient pb-7'>

      <TopMenuOnboarding
        title='Welcome to Camping Buddy'
        group={group}
        backHandler={false}
      />

      <div className={classes.content}>

        <div className={classes.contentText}>
          

        <div className={classes.contentTitle}>Where are you going?</div>

        <div className='flexRow'>
          Been here before?
          <Button 
            className='borderless'
            onClick={() => setMode('login')}
            >
            Log in
            </Button>
        </div>

        <div className="pledgeCheckboxHolder">
            <div className="pledgeCheckbox">
            <Checkbox defaultChecked={consent} onClick={()=>setConsent(!consent)} label={<label>I am over 16 years of age and consent to having my data shared with a <div className='underlinedInlineLink' onClick={()=>setShowResearchModal(!showResearchModal)}>QUT-led research project</div></label>} />
            </div>
          </div>

          {/*Add custom festival*/}

          {!festival && consent &&
          <EditFestival
            groupId={group?.id}
            festival={festival}
            pressHandler={selectFestival}
          />}

          {!festival && !consent && 
          <div className={classes.addEventContainer}>
            <Button className='addEventButton' onClick={()=>setAlertMessage({ title:"Hold on!", message: "Hey you need to check you agree to our terms and conditions first!"})}>
              <div className={classes.addEventButtonLabel}>Create my own event</div>
                <AddCircleIcon
                  className={classes.addEventButtonIcon}
                  style={{ fontSize: 26 }}
                />

            </Button>
          </div> 
          }

          {/*Map list of active festivals from database*/}

          <div className={classes.festivalsContainer}>

            {festivals?.map(festival =>

              <FestivalTile
                key={festival.id}
                festival={festival}
                pressHandler={selectFestival}
              />

            )}

          </div>



        </div>

      </div>

    {alertMessage && 
      <Alert 
      title={alertMessage.title}
      message={alertMessage.message}
      onConfirm={() => setAlertMessage()}
      />
    }

    {showResearchModal && 
        <Page 
        setShowPage={setShowResearchModal} 
        id='WGMeJ4lTAE6fByd1LVHl'
        />
      }



    </div>
  );
};

export default OnboardingFestival;