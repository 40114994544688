import React, { useState, useRef, useEffect } from 'react';

import classes from './ListHeader.module.css';

import ListAdd from './ListAdd';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Alert from '../Modals/Alert';

import SelectOptions from '../UI/SelectOptions';
import { OPTIONS_CATEGORIES } from '../../config';

import { doc, updateDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../config';

const ListHeader = ({ section, isExpanded, setIsExpanded, filterMembers, group, readonly, mode }) => {


    const header = section.title;
    const id = section.id;
    const [alertMessage, setAlertMessage] = useState();
    const [confirmMessage, setConfirmMessage] = useState();
    const [working, setWorking] = useState(false);
    const headerNameRef = useRef();
    //console.log(section.id, section.title)

    const [showCategoryOptionsModal, setShowCategoryOptionsModal] = useState(false);

    const calculateInputWidth = () => {
        const leng = headerNameRef.current ? headerNameRef.current.value.length : header.length;
        if (leng < 5) return leng + 2
        return leng
    }

    const [inputWidth, setInputWidth] = useState(calculateInputWidth)

    const deleteCategoryHandler = async () => {
        //set working to prevent multiple deletes
        if (working) return;
        setWorking(true);
        //delete items assigned to this category
        const newItems = group.items.filter(item => (!item.categories.includes(section.title)))
        //delete category from group
        const updatedCategories = [...group.categories];
        updatedCategories.splice(section.id, 1);
        try {
            const groupRef = doc(db, 'groups', group.id);
            await updateDoc(groupRef, {
                categories: updatedCategories,
                items: newItems,
                lastUpdate: Timestamp.fromDate(new Date())
            });
        } catch (err) {
            setAlertMessage({ title: `Error`, message: `There was a problem updating the categories. ` + err.message });
        }
        setWorking(false);
    }

    const handleKeyDown = (event) => {

        //resize category header input field width
        if (headerNameRef.current.value) { setInputWidth(calculateInputWidth) }

        //submit form if enter key is pressed
        if (event.key === 'Enter') {
            handleNameChange();
        }
    }

    const handleNameChange = async () => {
        if (working) return;

        const headerName = headerNameRef.current.value;

        //avoid duplicate categories
        if (headerName !== header) {
            const LCHeaders = group.categories.map(cat => cat.toLowerCase());
            if (LCHeaders.indexOf(headerName.toLowerCase()) !== -1) {
                alert('Doh! You already have this category in the list.');
                headerNameRef.current.value = header;
                return;
            }
        }

        setWorking(true);

        //update category names
        const updatedCategories = [...group.categories];
        updatedCategories[section.id] = headerName;

        //update items
        const updatedItems = [...group.items];
        updatedItems.forEach(item => {
            const headerIndex = item.categories.indexOf(header);
            if (headerIndex !== -1) {
                item.categories[headerIndex] = headerName
            }
        });

        try {
            const groupRef = doc(db, 'groups', group.id);
            await updateDoc(groupRef, {
                items: updatedItems,
                categories: updatedCategories,
                lastUpdate: Timestamp.fromDate(new Date())
            });
        } catch (err) {
            alert("There was a problem updating your group.")
        }
        setWorking(false);
    };

    //apply filters - get number of items according to which member / mode they are filtering
    const filteredItems = section.items.filter((item) => {
        let result = false;
        //for packing list filter any items that have been set to Bring
        if (mode ==='have') {
            item.organise.forEach(org => {if (org.method === 'Bring') result = true;});
        } else if (mode === 'need') {
            //for source list filter any items that have been set to Need
            item.organise.forEach(org => {if (org.method === 'Need') result = true;});
        } else {
            //for master list get all items 
            item.organise.forEach(org => { result = true; });
        }
        return (result) ? item : null;
    });

    const length = filteredItems.length;

    const selectCategoryOptionHandler = (val) => {
        if (val === 'private') setAlertMessage({ title: `Make your categories private`, message: `This feature is coming soon.` });
        if (val === 'delete') setConfirmMessage({ title: `Are you sure?`, message: `This action will permanently delete all items under this category.` });
        setShowCategoryOptionsModal(!showCategoryOptionsModal);
    }

    useEffect(() => {
        setInputWidth(calculateInputWidth);
    }, [header]);

    return (


        <div className={classes.checklistHeaderContainer}>

            <div className={classes.checklistHeaderGroupLeft} key={header}>

            <div
                className={classes.checklistHeaderNameInput}
                id='header'
                style={{ 'width': inputWidth + 'ch' }}
                onClick={() => setIsExpanded(!isExpanded)}
                >
                {header}
            </div>

                {/*!readonly &&
                    <ListAdd
                        group={group}
                        label={header}
                        id={id}
                        style={{ 'position': 'relative', 'top': '-10px' }}
                    />
                */}

                {/*!readonly &&
                    <SelectOptions
                        title='Category options'
                        icon='more'
                        options={OPTIONS_CATEGORIES}
                        selectedOption={null}
                        setSelectedOption={selectCategoryOptionHandler}
                    />
                */}

            </div>

            <div className={`${classes.checklistHeaderGroupRight}`} onClick={() => setIsExpanded(!isExpanded)} >

                <div className={classes.checklistHeaderText}>
                    {length} {length === 1 ? `item` : `items`}
                </div>

                {isExpanded ?
                    <ExpandMoreIcon className={classes.checklistHeaderIcon} style={{ fontSize: 30 }} /> :
                    <ChevronRightIcon className={classes.checklistHeaderIcon} style={{ cursor: 'pointer', fontSize: 30 }} />
                }

            </div>

            {alertMessage &&
                <Alert
                    title={alertMessage.title}
                    message={alertMessage.message}
                    onConfirm={() => setAlertMessage()}
                />
            }

            {confirmMessage &&
                <Alert
                    title={confirmMessage.title}
                    message={confirmMessage.message}
                    onCancel={() => setConfirmMessage()}
                    onConfirm={() => { deleteCategoryHandler(); setConfirmMessage(); }}
                />
            }


        </div>

    );

}

export default ListHeader;