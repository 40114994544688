import React, {  useState, useEffect, useRef } from 'react';

import {useLocation} from "react-router-dom";

import classes from './PublicShare.module.css';

import Button from '../UI/Button';
import Loading from '../UI/Loading';
import Alert from '../Modals/Alert';
import { auth, db } from '../../config';
import HaveNeedListItem from '../List/HaveNeedListItem';
import {  doc, onSnapshot, updateDoc } from 'firebase/firestore';
import SendIcon from '@mui/icons-material/Send';
import ReactGA from 'react-ga4';

import { signInWithEmailAndPassword} from 'firebase/auth';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }

const List = () => {

    const user = auth.currentUser;

    const [group, setGroup] = useState();
    const [items, setItems] = useState();
    const [heading, setHeading] = useState();
    const [subheading, setSubheading] = useState();
    const [filterMembers, setFilterMembers] = useState();
    const [alertMessage, setAlertMessage] = useState();
    const [selectedItems, setSelectedItems] = useState([]);
    const [working, setWorking] = useState(false);
    const [lenderName, setLenderName] = useState();
 
    const confirmLendMessage = 'We will let the organiser know.'
    const nameRef=useRef();

    let query = useQuery();

    const updateCampingBuddy = async() => {
        //update item status - set for all items (no multiples yet)
        //make a shallow copy of group items
        const updatedItems = [...group?.items];
        //go through all group items and update their status if they are in the list that have just been offered
        updatedItems?.forEach(item => {
            if (selectedItems?.includes(item.id)) {
            item.organise?.map( org => {org.status = 'Offered'; org.helper = lenderName})
            //item.status=`Offered by ${lenderName}`;
            }
        })
        try {
            const groupRef = doc(db, 'groups', group.id);
            await updateDoc(groupRef, {
                items: updatedItems
            });
        } catch (err) {
            alert("There was a problem updating your item.")
        }
        //create notification for group owner
        setAlertMessage({ title:"Nice work!", message: confirmLendMessage});
        setWorking(false);
    }

    const confirmLend = async () => {
        if (selectedItems.length===0) {
            setAlertMessage({ title:"Hang on!", message: `You haven't specified any gear to lend.`}); 
            return;
        }
        if (!lenderName) {
            setAlertMessage({ title:"Hang on!", message: `Don't forget to enter your name.`}); 
            return;
        }
        setWorking(true);
        //sign in if they aren't signed in
        if (!user) {
            //not signed in - sign in with external user account
            signInWithEmailAndPassword(auth, 'externaluser@gmail.com', 'Externaluser@123')
            .then((userCredential) => {
              // Signed in
              updateCampingBuddy();
            })
            .catch((error) => {
              const errorCode = error.code;
              let errorMessage = error.message;
              switch (errorCode) {
                case 'auth/user-not-found' :
                  errorMessage = "We can't find a user with this email address"; break;
                case 'auth/wrong-password' :
                  errorMessage = "Password is incorrect"; break;
                case 'auth/too-many-requests' :
                  errorMessage = "Too many failed login attempts. Try again later or reset your password."; break;
                default: errorMessage = "General error with sign in. Error code: "+errorCode;
               }
              setAlertMessage({ title:"Oops", message: errorMessage});
              setWorking(false);
            });
        } else {
            updateCampingBuddy();
        }
    }

    useEffect(() => {
        const groupId = query.get("id");
       if (!groupId) return;
        setGroup();
        const unsubscribe = onSnapshot(doc(db, 'groups', groupId), (doc) => {
        if (doc.data()) {
          setGroup(doc.data());
        }
      });
      ReactGA?.send({ hitType: "pageview", page: '/publicShare', title: 'Public Share' });
      return () => {
        unsubscribe();
        setGroup();
      };
      }, []);

      

      useEffect(() => {
        if (!group) return;

        const itemIds = query.get("items");
        const itemIdsArray = itemIds ? itemIds.split(",") : [];
        if (!itemIds) return;
        //only show items that are included in the query string and also haven't already been offerered by someone else
        const itemsToShare = itemIdsArray?.length>0 ? group.items.filter(
            item => itemIdsArray.includes(item.id)
            && item.organise?.find( org => org.status === 'Requested' )
        ) : group.items;
        setItems(itemsToShare);

        const festival = group.festival;
        const groupOwner = group.members.filter(mem => mem.owner);
        const groupOwnerName = 
            (groupOwner && groupOwner.length > 0) ? 
            (groupOwner[0].name !== 'Guest' && groupOwner[0].name !=='') ? groupOwner[0].name : 
            'One of your mates' : 'One of your mates';
        const headingText = `${groupOwnerName} is going to ${festival.name} and needs to borrow some gear`;
        setHeading(headingText)

        const startDate = festival.startdate && new Date(festival.startdate).toLocaleDateString();
        const endDate = festival.enddate && new Date(festival.enddate).toLocaleDateString();
        const subHeadingText = `Can you lend any of the following from ${startDate} to ${endDate}?`;
        setSubheading(subHeadingText)
      }, [group]);



    if (!items || !group || working) return (
        <Loading msg='Hold on one sec...'/>
    )
    
    if (items.length === 0) {
        return (
            <div className='container bg'>
            <div className={classes.checklistContainer}>
    
                <div className={classes.checklistHeading}>
                    {heading}
                </div>  
    
                <div>
                    Everything has been sorted! 
                </div>   
            
            </div>
    
            </div>
        )
    }

    return (
        <div className='container bg'>
        <div className={classes.checklistContainer}>

            <div className={classes.checklistHeading}>
                {heading}
            </div>  

            <div>
                {subheading}
            </div>   

            <div className={classes.checklistSections}>
            { items?.map ((item, index) => 
                <HaveNeedListItem
                    group={group}
                    key={index} 
                    item={item} 
                    filterMembers={filterMembers}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    methods={['Need']}
                    mode='share'
                />
            ) }
            </div>

            
            <div className={classes.nameInput}>
                    <input 
                        id="name" 
                        required 
                        placeholder='Your name*' 
                        ref={nameRef}  
                        spellCheck='false'
                        onChange={(e)=>setLenderName(e.target.value)}
                        />
                    </div>

            <div className={classes.callToAction}>
                <Button 
                    className='primary'
                    style={{marginTop:0}}
                    onClick={confirmLend}>
                    {`Let them know`}
                    <SendIcon style={{ color: "#000000", fontSize: 20, verticalAlign: 'middle', padding: '0 0 2px 5px'}}/>
                </Button>
            </div>

            {/*group.notes && <div className={classes.groupNotes}>{group.notes}</div>*/}

            <div className={classes.callToActionFooter}>
                Powered by
                <Button 
                    className='borderless'
                    onClick={()=>window.open('https://www.campingbuddy.com.au/', '_self')}>
                    Camping Buddy
                </Button>
            </div>
        
        </div>

        {alertMessage && 
            <Alert 
            title={alertMessage.title}
            message={alertMessage.message}
            onConfirm={() => setAlertMessage()}
            />
        }

        </div>

    );

}

export default List;