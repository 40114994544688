import React, { useState, useRef } from 'react';

import classes from './ListItem.module.css';

import { db } from '../../config';
import { doc, updateDoc, Timestamp } from 'firebase/firestore';

import ListOrganise from './ListOrganise';
import ListItemProgress from './ListItemProgress';
import EditGroup from '../Group/EditGroup';



const ListItem = ({ group, item, category, handleDragStart, handleDragOver, handleDragEnd, filterMembers }) => {

    const itemNameRef = useRef();
    const [working, setWorking] = useState(false);
    const [groupEditMode, setGroupEditMode] = useState(false);


    const filteredItems = item.organise?.filter(org => filterMembers?.includes(org.assigned));
   //if (item?.name === "Lantern") console.log(item);

    const numberItems = filteredItems?.length;

    const handleKeyDown = (event) => {

        //submit form if enter key is pressed
        if (event.key === 'Enter') {
            handleNameChange();
        }
    }

    const handleNameChange = async () => {
        if (working) return;

        const itemName = itemNameRef.current.value;

        //avoid duplicate items
        if (itemNameRef.current.value !== item.name) {
            const LCItems = group.items.map(item => item.selected && item.organise.length > 0 && item.name.toLowerCase());
            if (LCItems.indexOf(itemName.toLowerCase()) !== -1) {
                alert('Doh! You already have this item in the list.');
                itemNameRef.current.value = item.name;
                return;
            }
        }

        setWorking(true);
        const itemIndex = group.items.findIndex(i => i.id === item.id);
        const updatedItem = {
            ...group.items[itemIndex],
            name: itemName
        }
        const updatedItems = [...group.items];
        updatedItems[itemIndex] = updatedItem;
        try {
            const groupRef = doc(db, 'groups', group.id);
            await updateDoc(groupRef, {
                items: updatedItems,
                lastUpdate: Timestamp.fromDate(new Date())
            });
        } catch (err) {
            alert("There was a problem updating your list.")
        }
        setWorking(false);
    };

    if (!item) return;

    if (groupEditMode) return (
        <EditGroup group={group} onConfirm={() => setGroupEditMode(!groupEditMode)} />
    )

    return (

        <div
            className={classes.checklistItemContainer}
            key={item.name}
            draggable
            onDragStart={(e) => handleDragStart(e, item.id, item.name, category)}
            onDragOver={(e) => handleDragOver(e, item.id, item.name, category)}
            onDragEnd={(e) => handleDragEnd(e)}
        >
            <ListOrganise
                    group={group}
                    item={item}
                    name={item.name}
                    editGroupHandler={() => setGroupEditMode(true)}
                    filterMembers={filterMembers}
                />

        </div>

    );

}

export default ListItem;