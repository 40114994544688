import React, {useState, useMemo, useEffect } from 'react';

import classes from './ListSection.module.css';

import ListHeader from './ListHeader';
import HaveNeedListItem from './HaveNeedListItem';

const ListSectionOpen = ( { group, section, filterMembers, readonly, mode } ) => {
    
    const [isExpanded, setIsExpanded] = useState(true);
    const [items, setItems] = useState();
    const [methods, setMethods] = useState([]);

    useEffect(() => {
        setMethods([])
        if (mode === 'have') setMethods(['Bring']);
        if (mode === 'need') setMethods(['Need']);
    }, [mode]);


    //filter items according to whether they are still needed or they have them
    useEffect(() => {
        if (methods.length < 1 || !section) return;
        const items = section.items?.filter((item,index) => {
            //return item;
             //if mode has been set to need or bring
            let result = false;
            item.organise.forEach(org => {
                if ( methods.includes(org.method) ) result = true;
            });
            return result ? item : null; 
        });
        setItems(items);
    }, [section, methods]);

    if (section.title === "Cleaning") return;

    return (

        <div className={classes.checklistSectionContainer}>

            <ListHeader 
                section={section}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                filterMembers={filterMembers}
                group={group}
                readonly={readonly}
                mode={mode}
                />

                { isExpanded && !readonly && 

                    <div className={classes.checklistItemsContainerOpen}>
                    
                        <div className={classes.checklistItemList}>

                        { items?.map ((item, index) => 
                        
                            <HaveNeedListItem
                                group={group}
                                key={index} 
                                item={item} 
                                filterMembers={filterMembers}
                                methods={methods}
                                mode={mode}
                            />
                        ) }

                        </div>

                    </div>
                }

        </div>

    );

}

export default ListSectionOpen;