import React, { useState } from 'react';
import classes from './ListItemAdd.module.css';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Button from '../UI/Button';

import { doc, updateDoc, Timestamp } from 'firebase/firestore';
import { auth, db } from '../../config';
import Alert from '../Modals/Alert';

const ListItemAdd = ({ direction, numberItems, numberTotalItems, group, item, method }) => {

    const user = auth.currentUser;
    const [alertMessage, setAlertMessage] = useState();

    const [working, setWorking] = useState(false);
    const newMethod = method ? method : 'Bring';

    const changeNumberOfItems = async () => {
        setWorking(true);
        const itemIndex = group.items.findIndex(i => i.id === item.id);
        //const updatedItemOrganise = item.organise ? [...item.organise] : [];
        const updatedItemNeed = item.organise ? item.organise.filter(org => org.method==="Need") : [];
        const updatedItemHave = item.organise ? item.organise.filter(org => org.method!=="Need") : [];
        //who should it be assigned to? Assign to user in this iteration
        const assignee = user.uid;
        const defaultItemOrganiseObj = { assigned: assignee, checked: false, method: newMethod, status:null, helper:null }
        if (direction === 'up') {
            newMethod ===  'Need' ? 
                updatedItemNeed.push(defaultItemOrganiseObj) : 
                updatedItemHave.push(defaultItemOrganiseObj);
        } else {
            if (numberTotalItems===1) {
                newMethod ===  'Need' ? 
                    updatedItemNeed.splice((updatedItemNeed.length - 1), 1):
                    updatedItemHave.splice((updatedItemHave.length - 1), 1);
                //updatedItemOrganise.splice((updatedItemOrganise.length - 1), 1);
                //if it's the last item just remove the method not the item
                //defaultItemOrganiseObj.method = null
                //updatedItemOrganise[0] = defaultItemOrganiseObj;
            } else {
                //there are more than one item so just delete the item
               // updatedItemOrganise.splice((updatedItemOrganise.length - 1), 1);
                newMethod ===  'Need' ? 
                    updatedItemNeed.splice((updatedItemNeed.length - 1), 1):
                    updatedItemHave.splice((updatedItemHave.length - 1), 1);
            }
        }
        const updatedItemOrganise = updatedItemHave.concat(updatedItemNeed);
        const updatedItem = {
            ...group.items[itemIndex],
            organise: updatedItemOrganise
        }
        const updatedItems = [...group.items];
        updatedItems[itemIndex] = updatedItem;
        try {
            const groupRef = doc(db, 'groups', group.id);
            await updateDoc(groupRef, {
                items: updatedItems,
                lastUpdate: Timestamp.fromDate(new Date())
            });
        } catch (err) {
            alert("There was a problem updating your group.")
        }
        setWorking(false);
    }


    const addHandler = () => {
        if (working) return;
        if (direction === 'up') {
            changeNumberOfItems()
        } else {
            if (numberItems > 1) {
                changeNumberOfItems();
            } else if (numberItems === 1) {
                changeNumberOfItems();
                //setAlertMessage({ title: `Alert`, message: 'Are you sure you want to remove the last one?' });
            }
        }
    }


    const renderIcon = () => {

        return (
            direction === 'up' ?
                <div className={classes.numberAdd}>
                    <span>+</span>
                </div>
                :
                <div className={numberItems > 0 ? classes.numberRemove : classes.hidden}>
                    <span>-</span>
                </div>
        )

    }



    return (

        <>

            <Button
                onClick={addHandler}
                className='noStyle'
            >
                {renderIcon()}
            </Button>

            {alertMessage &&
                <Alert
                    title={alertMessage.title}
                    message={alertMessage.message}
                    onConfirm={() => { changeNumberOfItems(); setAlertMessage(); }}
                    onCancel={() => setAlertMessage()}
                />
            }

        </>


    );

}

export default ListItemAdd;