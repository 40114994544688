import React, { useState, useEffect } from 'react';

import classes from '../List/ListItem.module.css';
import Button from '../UI/Button';
import ClearIcon from '@mui/icons-material/Clear';
import ListItemAdd from '../List/ListItemAdd';

const ShareListItem = ({ item, group, removeItem }) => {

    const [numberItemsNeed, setNumberItemsNeed] = useState(1);
    const [numberTotalItems, setNumberTotalItems] = useState(1);

    let status = item.organise?.find( org => org.status && org.status!=="Hired" )?.status
    const helper = (status === 'Offered') ? item.organise?.find( org => org.helper )?.helper : null;
    if (status && helper) status = `${status} by ${helper}`

    if (item?.name === "Rope") console.log(item);

    useEffect(() => {
        if (!item) return;
        //count total items
        setNumberTotalItems(item.organise?.length);
        //only count items that we need
        const filteredMethodItems = item.organise?.filter( subitem => subitem.method === 'Need');
        setNumberItemsNeed(filteredMethodItems.length)
    }, [item]);

    if (!item) return;

    return (

        <div className={classes.shareItemContainer}>

            <div className={classes.shareItemContainerCell}> 
            <Button className='noStyle' onClick={() => removeItem(item)}>
                    <ClearIcon style={{ display: 'flex' }} />
            </Button>
            <div className={classes.shareItemContainerCellInfo}>
                <div className={classes.shareItemContainerCellName}> 
                { item.name }
                { numberItemsNeed > 1 && ` x${numberItemsNeed}` }
                </div>
                {status && <div className={classes.itemStatus}> { ` ${status}` } </div>}
            </div>
            </div>

            <div className={classes.shareItemContainerCell}> 
                 {/*Items you need section*/}
                <div className={classes.numberContainer}>
                
                <ListItemAdd
                    direction='down'
                    numberItems={numberItemsNeed}
                    numberTotalItems={numberTotalItems}
                    group={group}
                    item={item}
                    method='Need'
                />
                <div className={classes.numberText}>{numberItemsNeed}</div>
                <ListItemAdd
                    direction='up'
                    numberItems={numberItemsNeed}
                    numberTotalItems={numberTotalItems}
                    group={group}
                    item={item}
                    method='Need'
                />
                </div>
            </div>

        </div>

    );

}

export default ShareListItem;