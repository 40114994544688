import React, { useMemo, useState, useContext, useEffect } from 'react';

import classes from './List.module.css';

import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../config';


import Loading from '../UI/Loading';

import ListSection from './ListSection';
import ListTop from './ListTop';
import ListOptions from './ListOptions';

import { UserContext } from '../../store/user-context';

const SectionItems = ({ sectionItems, group, filterMembers }) => {

    //drag and drop ordering
    const [working, setWorking] = useState(false);
    const [currentDrag, setCurrentDrag] = useState();
    const [currentDragOver, setCurrentDragOver] = useState();
    const readonly = false; 
    
    //console.log(group.categories)

    //when items are drag and dropped the group items must be reordered to suit
    //handle sort is called from child component ChecklistItem
    const handleDragEnd = async (e) => {
        setWorking(false);

        //don't do anything if they haven't dragged or dropped or it's still working
        if (working || !currentDrag || !currentDragOver) return;
        //do nothing if they've dropped the item onto itself
        if (currentDrag.id === currentDragOver.id) return;
        e.preventDefault();
        setWorking(true);

        //duplicate items
        const _items = [...group.items];
        const dragIndex = _items.findIndex(item => item.id === currentDrag.id);
        const dragOverIndex = _items.findIndex(item => item.id === currentDragOver.id);
        //get drag item object
        const dragItemContent = _items[dragIndex];

        //make sure drag item category array includes the category they are dropping into (in case they are dragging and dropping items between categories)
        if (!dragItemContent.categories.includes(currentDragOver.category)) {
            //find category they were dragging from and replace with drop category
            const currentCatIndex = dragItemContent.categories.indexOf(currentDrag.category);
            dragItemContent.categories[currentCatIndex] = currentDragOver.category;
        }
        //remove previous drag item from the group items
        _items.splice(dragIndex, 1);
        //add to where the drop item is 
        _items.splice(dragOverIndex, 0, dragItemContent)
        //reset order property according to new position in array
        _items.map((item, index) => item.order = index);
        //console.log(_items.map(item => (item.order+' '+item.name+' '+item.categories[0]))); return
        /*update order of items in groups collection in firebase*/ 
        
        if (group && group.id) {
            try {
                const groupRef = doc(db, 'groups', group.id);
                await updateDoc(groupRef, {
                    items: _items
                });
            } catch (err) {
                alert("There was a problem updating your group.")
            }
        }
        
        setCurrentDrag();
        setCurrentDragOver();
        setWorking(false);
    }

    //handleDragStart function is called from child component ChecklistItem.
    const handleDragStart = async (e, id, name, category) => {
        setCurrentDrag({ id: id, name: name, category: category })
    }

    //handleDragOver function is called from child component ChecklistItem. Add prevent default to on drag over functionality to call the sort function faster
    const handleDragOver = async (e, id, name, category) => {
        e.preventDefault();
        setCurrentDragOver({ id: id, name: name, category: category })
    }

    return (
        <div className={classes.checklistSections}>
            {
                sectionItems?.map((section, index) => (
                    <ListSection
                        group={group}
                        key={index}
                        section={section}
                        filterMembers={filterMembers}
                        handleDragStart={handleDragStart}
                        handleDragOver={handleDragOver}
                        handleDragEnd={handleDragEnd}
                        readonly={readonly}
                    />
                ))
            }
        </div>
    )
}

const MasterList = ({ group }) => {

    //get previous sort mode from user context
    const UserCtx = useContext(UserContext);

    const initialMembers = [...group?.memberIds, 'unassigned'];
    const [filterMembers, setFilterMembers] = useState([...group?.memberIds, 'unassigned']);

    const [categories, setCategories] = useState(group?.categories);

    useEffect(() => {
        if (filterMembers.length > 1) {
            setFilterMembers(initialMembers);
        }
    }, [group?.members]);

    //console.log(group.id)
    const sectionItems = useMemo(() => {
        //list and organise items according to sort mode 
        const organisedItems = [];
         //organise by categories
         categories.forEach((category, index) => {
            //get any group items that have this category
            const categoryItems = group?.items.filter(item => (item.categories.includes(category)))
            //Sort alphabetically (name)
            if (categoryItems.length > 1) categoryItems.reverse();
            //set up object for the category and add items as an array
            const categoryObj = { id: index, title: category, items: categoryItems }
            //add category object to filtered items array
            organisedItems.push(categoryObj);
        });
        return organisedItems;
    }, [group, categories]);


    useEffect(() => {
        if (!group) return;
        //group has changed - reset member filter
        //console.log('group has changed',group);
        setCategories(group.categories);
        //have turned this off because it's buggy - new members aren't added to checklist filter by default anymore
        //setFilterMembers([...group.memberIds, 'unassigned']);
    }, [group]);


    if (!sectionItems) return (
        <Loading msg='Loading...' />
    )

    return (

        <div className={classes.checklistContainer}>

            <ListTop group={group} mode='masterlist'/>

            <SectionItems sectionItems={sectionItems} group={group} filterMembers={filterMembers} />

            <ListOptions group={group} mode='masterlist'/>

        </div>

    );

}

export default MasterList;