import React, { useState, useEffect, useContext } from 'react';
import classes from './OnboardingFestival.module.css';

import EditFestival from '../Forms/EditFestival';
import FestivalTile from './FestivalTile';
import Loading from '../UI/Loading';

import TopMenuOnboarding from '../Navigation/TopMenuOnboarding';
import { UserContext } from '../../store/user-context';

import { collection, query, where, onSnapshot, doc, updateDoc, Timestamp } from 'firebase/firestore';
import { auth, db, } from '../../config';
import Button from '../UI/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const NewFestival = ({ group }) => {

  {/*Called from Root. Shows options to select a festival if creating a new event */ }

  const [working, setWorking] = useState(false);
  const user = auth.currentUser;
  const [festivals, setFestivals] = useState([]);
  const [defaultItems, setDefaultItems] = useState([]);
  const [festival, setFestival] = useState(group?.festival);
  const UserCtx = useContext(UserContext);
  const groups = UserCtx.groups;

  {/*Add the selected festival to the user's group in the database*/ }

  const saveFestivalToGroup = async () => {
    if (group && group.id) {
      try {
        setWorking(true);
        const groupRef = doc(db, 'groups', group.id);

        //check user doesn't already have this festival/event
        const filteredGroups = groups?.filter(item => item.festival && festival.name === item.festival.name);
        if (filteredGroups.length) {
          alert("Festival name already exists")
          setFestival(group?.festival)
        } else {
            //first pre configure group items. 
            //If it's a custom festival it won't have any items so we have to assign the default items
            const groupDefaultItems = festival.items? [...festival.items] : defaultItems;
            //remove the 'essentials' and  'Cleaning' categories for now
            const availableItems = groupDefaultItems.filter(item => (!item.categories.includes('Essentials') && !item.categories.includes('Cleaning')));
            //set up some default stuff for each item
            const preparedItems = availableItems.map((item,index) => (
            {...item, selected: true, organise: [{ assigned: user.uid, checked: false }], order: index}
            ))

            //don't save festival items to DB (we are saving items at the group level)
            const preparedFestival = { ...festival }
            delete preparedFestival.items;

          await updateDoc(groupRef, {
            items: preparedItems,
            festival: preparedFestival,
            lastUpdate: Timestamp.fromDate(new Date())
          });
        }
      } catch (err) {
        alert("There was a problem updating your group.")
      }
      setWorking(false);
    }
  }

  const handleCancel = async () => {
    const updatesToGroup = {
      members: [],
      memberIds: [],
      memberEmails: [],
      items: [],
    }
    try {
      const groupRef = doc(db, 'groups', group.id);
      await updateDoc(groupRef, updatesToGroup);
      UserCtx.setCurrent('delete')
    } catch (err) {
      alert("There was a problem updating your group.")
    }
  }

  useEffect(() => {
    if (!festival) return;
    saveFestivalToGroup();
  }, [festival]);

  useEffect(() => {
    //set up listener for festivals
    if (!user) return;
    setFestivals([]);
    const q = query(collection(db, 'festivals'), where("active", "==", true));
    const unsubscribe = onSnapshot(q,
      (querySnapshot) => {
        const festivalsSnapshot = [];
        querySnapshot.forEach((doc) => {
          //remove default items from festivals (this will be added in next onboarding screen)
          const dataObj = { ...doc.data() }
          delete dataObj.items;
          festivalsSnapshot.push(dataObj);
        });
        setFestivals(festivalsSnapshot);
      },
      (error) => {
        console.log(error.message);
      });

      //they have created a custom event - set up listener for default items / master packing list
      const q2 = query(collection(db, "defaultitems"));
      setDefaultItems([])
      const unsubscribe2 = onSnapshot(q2, (querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => { items.push(doc.data()); });
        setDefaultItems(items);
      });

    return () => {
      unsubscribe();
      unsubscribe2();
      setFestivals();
    };
  }, [user])

  if (working) return <Loading msg='Loading' />


  return (
    <div className='container bg-green-gradient pb-7'>

      <TopMenuOnboarding
        title='Where are you going?'
        group={group}
        backHandler={false}
      />

      <div className={classes.content}>

        <div className={classes.contentText}>

          {/*Add custom festival*/}

          <EditFestival
            groupId={group?.id}
            festival={festival}
            pressHandler={setFestival}
          />

          {/*Map list of active festivals from database*/}

          <div className={classes.festivalsContainer}>

            {festivals?.map(festival =>

              <FestivalTile
                key={festival.id}
                festival={festival}
                pressHandler={setFestival}
              />

            )}

          </div>

        </div>

      </div>
      {groups.length > 1 &&
        <Button className='cancelEventButton' onClick={handleCancel}>
          <span>Cancel</span>
          <span className='icon'>
            <AddCircleIcon
              style={{ fontSize: 26 }}
            />
          </span>
        </Button>
      }
    </div>
  );
};

export default NewFestival;