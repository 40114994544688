import React, { useState, useEffect } from 'react';
import classes from './ListItemProgress.module.css';

import Button from '../UI/Button';
import CheckIcon from '@mui/icons-material/Check';


const ListItemShare = ({  item, setSelectedItems, selectedItems }) => {

    const [lendItem, setLendItem] = useState(selectedItems?.includes(item?.id));

    const toggleItemStatus = () => {
        const newSelection = [...selectedItems];
        if (lendItem) {
            setLendItem(false);
            //remove from selectedItems Array
            const itemIndex = newSelection.indexOf(item.id);
            if (itemIndex!==-1) newSelection.splice(itemIndex, 1);
        } else {
            setLendItem(true);
            //add to selectedItems Array
            newSelection.push(item.id);
        }
        setSelectedItems(newSelection);
    }

    if (lendItem) return (
        <Button 
            onClick={toggleItemStatus}
            className='noStyle'
            >
            <CheckIcon style={{marginTop:0}}  />
        </Button>
    )

    return (

        <div >
            <Button 
            onClick={toggleItemStatus}
            style={{marginTop:0}}
            >
            {`I can lend this`}
            </Button>
        </div>

    );

}

export default ListItemShare;