import React, { useState, useRef} from 'react';
import OnboardingFestival from '../components/Onboarding/OnboardingFestival';
import Login from './Login';

const Onboarding = () => {

  const [mode, setMode] = useState('guest');
  const group = null;

  return (mode === 'login') ? 
    <Login setMode={setMode}/> : 
    <OnboardingFestival group={group} setMode={setMode} />
}

export default Onboarding;