

import classes from './List.module.css';


const ListTop = ({ mode, numberItems }) => {

    const title = 
    (mode === 'havelist') ? 'Packing list' :
    (mode === 'needlist') ? 'Gear you need' : 
    'Here is what you need...';

    const subtitle = 
    (numberItems === 0) ? '' :
    (mode === 'havelist') ? 'Use this to help make sure you pack all the things you need' :
    (mode === 'needlist') ? 'This is what you still need. Why not borrow these items?' : 
    'Do you have everything?';

    return (
        <div className={classes.checklistTop}>

            <div className={classes.checklistTopTitle}>
            <h2 className={classes.checklistTitle}>{title}</h2>
            <div className={classes.checklistsubTitle}>{subtitle}</div>
            </div>
        </div>
    );

}

export default ListTop;